import translation_hu from "./locale/translation.hu.json"
import translation_en from "./locale/translation.en.json"

export const getTranslation = (lang = null) => {
    if(lang === "hu"){
        return translation_hu;
    }else{
        return translation_en;
    }
}
