import { gql } from 'apollo-boost';


export const GET_DICTIONARY_OPTIONS = gql`
    {
        dic1: dictionary(dictionaryType: 1){
            id
            dictionary_type
            name
        }
        dic2: dictionary(dictionaryType: 2){
            id
            dictionary_type
            name
        }
        dic3: dictionary(dictionaryType: 3){
            id
            dictionary_type
            name
        }
    }
`;

export const GET_ROUTE_SEARCH_RESULT = gql`
    query routes($tematika: Int, $terulet: Int, $tipus: Int, $limit: Int, $offset: Int, $like: String, $active:Boolean) {
        routes(tematika: $tematika, terulet: $terulet, tipus: $tipus, limit: $limit, offset: $offset, like: $like , active: $active){
            count
            route{
                id,
                title{
                    en,
                    hu
                },
                description{
                    en,
                    hu
                },
                distance,
                difficulty,
                active,
                routes_attributes{
                    id
                    routes_id
                    dictionary_id
                    dictionary{
                        dictionary_type
                    }
                }
                duration,
                image_id,
                image{
                    id
                    mime_type
                    original_name
                    path
                },
                route_markers{
                    id
                    routes_id
                    markers_id
                    order
                    marker{
                        id
                        lat
                        lng
                        address
                    }
                },
                marker_count
            }
        }
    }
`;
export const GET_ROUTE_BY_ID = gql`
    mutation getRouteById($id: Int!){
        routesById(id: $id){
            id,
            title{
                en,
                hu
            },
            description{
                en,
                hu
            },
            image_id,
            image{
                id
                mime_type
                original_name
                path
            },
            duration,
            distance,
            difficulty,
            active,
            routes_attributes{
                id
                routes_id
                dictionary_id
                dictionary{
                    dictionary_type
                }
            }
            route_markers{
                id
                routes_id
                markers_id
                order
                marker{
                    id
                    title{
                        hu
                        en
                    }
                    description{
                        hu
                        en
                    }
                    lat
                    lng
                    helper
                    address
                    image_id,
                    markerImage{
                        id
                        mime_type
                        original_name
                        path
                        description{
                            hu
                            en
                        }
                    },
                    video_id,
                    video{
                        id
                        mime_type
                        original_name
                        path
                        description{
                            hu
                            en
                        }
                    },
                    sound_id,
                    sound{
                        id
                        mime_type
                        original_name
                        path
                        description{
                            hu
                            en
                        }
                    },
                }
            }
        }
    }
`;


export const GET_ROUTE_MARKER_ID = gql`
    mutation getMarkerById($id: Int){
        markersById(id: $id){
            id,
            title{
                en,
                hu
            },
            description{
                en,
                hu
            },
            address,
            lat,
            lng,
            image_id,
            image{
                id
                mime_type
                original_name
                path
            },
            video_id,
            video{
                id
                mime_type
                original_name
                path
            },
            sound_id,
            sound{
                id
                mime_type
                original_name
                path
            },
            markers_attributes{
                id
                markers_id
                dictionary_id
                dictionary{
                    dictionary_type
                }
            }
        }
    }
`;

export const GET_MARKERS = gql`
    query GetMarkers($address: String, $tematika: Int, $terulet: Int, $tipus: Int, $limit: Int, $offset: Int, $sorted_column: String, $direction: String){
        markers(address: $address, tematika: $tematika, terulet: $terulet, tipus: $tipus, limit: $limit, offset: $offset, sorted_column: $sorted_column, direction: $direction ){
            count
            marker{
                id,
                title{
                    en,
                    hu
                },
                description{
                    en,
                    hu
                },
                address,
                lat,
                lng,
                image_id,
                image{
                    id
                    mime_type
                    original_name
                    path
                },
                video_id,
                video{
                    id
                    mime_type
                    original_name
                    path
                },
                sound_id,
                sound{
                    id
                    mime_type
                    original_name
                    path
                },
                markers_attributes{
                    id
                    markers_id
                    dictionary_id
                    dictionary{
                        dictionary_type
                    }
                }
            }
        }
    }
`;
export const GET_RECOMMENDED_ROUTE = gql`
    query getRecommendedRoutes($routeId: Int, $markerId: Int){
        getRecommendedRoutes(routeId: $routeId, markerId: $markerId){
            id,
            title{
                en,
                hu
            },
            description{
                en,
                hu
            },
            image_id,
            image{
                id
                mime_type
                original_name
                path
            },
            duration,
            distance,
            difficulty,
            active,
            route_markers{
                id
            }
        }
    }
`;


