import React from 'react';
import src from '../img/bvafooterlogo.png'
import Container from "@material-ui/core/Container";

function Footer() {
    return (
        <footer>
            <Container maxWidth="lg">
                <img src={src} alt={"bva-logo"}/>
            </Container>
        </footer>
    );
}

export default Footer;
