import React from 'react';
import src from '../img/bvafooterlogo.png'
import Container from "@material-ui/core/Container";

function MediaComponent({media, lang}) {

    if(!media){
        return null;
    }

    switch (media.mime_type.split("/")[0]) {
        case "audio":
            return (<audio controls>
                <source src={media.path} type={media.mime_type}/>
            </audio>);
        case "video":
            return (<video controls>
                <source src={media.path} type="video/mp4" />
                Your browser does not support the video tag.
            </video>);
        case "image":
            return [<div style={{margin: "0 auto"}}><img src={media.path}/><p style={{textAlign: "center", fontStyle: "italic"}} className={"kepalairas"}>{media.description ? media.description[lang] : null}</p></div>]
    }

    return null;
}

export default MediaComponent;
