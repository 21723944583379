import React, {useEffect, useState} from 'react';
import GoogleMap from "../components/GoogleMap";
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";
import {apiKey} from "../const";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {GET_MARKERS, GET_ROUTE_BY_ID} from "../graphql/graphql";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import HomeIcon from "@material-ui/icons/Home";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import RoomIcon from "@material-ui/icons/Room";
import MapIcon from "@material-ui/icons/Map";
import {useHistory} from "react-router-dom";
import GreenDot from "../img/marker_green.png";
import {GApageView} from "../index";
/*global google*/

function MobileMap({selectedRoute, selectedMarker, google, mobile, route, singleAllomas, translation, lang}) {

    let history = useHistory();

    const [state, setState] = useState({
        markerInfo : null,
    });

    const [ getRoute, { data, loading, error }] = useMutation(GET_ROUTE_BY_ID);
    const { data: markerData, loading: markerLoading, error:markerError } = useQuery(GET_MARKERS);


    useEffect(()=>{
        GApageView(window.location.href);
        if(route && selectedRoute){
            getRoute({
                variables: {
                    id: selectedRoute*1
                }
            });
        }
    }, [route])


    const showMarkerInfo = (markerData) => {
        setState(prevState => {
            return {...prevState, markerInfo: markerData}
        })
    }

    const handleChange = (event, newValue) => {
        switch(newValue){
            case 0 :
                history.push("/");
                break;
            case 1 :
                history.push("/route/"+selectedRoute);
                break;
            case 2 :
                if(singleAllomas){
                    history.push("/allomas/"+singleAllomas);
                }else{
                    history.push("/allomas/"+selectedRoute+"/"+selectedMarker)
                }
                break;
        }
    };

    if(route && selectedRoute){
        return ([<GoogleMap height={"calc(100vh)"} route={data} key={"1"} selectedRoute={selectedRoute} selectedMarker={selectedMarker}/>,
            <AppBar position="static" style={{background:"#fff"}} className={'stickToBottom submenu'} key={"2"}>
                <Tabs
                    value={3}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab component="a"  icon={<HomeIcon />}/>
                    <Tab component="a"  icon={<DirectionsWalkIcon />} disabled={!selectedRoute}/>
                    <Tab component="a"  icon={<RoomIcon />} disabled={!selectedRoute || !selectedMarker}/>
                    <Tab component="a"  icon={<MapIcon />}/>
                </Tabs>
            </AppBar>
        ]);
    }

    return ([<div style={{margin: "0 0", position:"relative", width: "100%", height: "calc(100vh)"}} key={"1"}>
        {
            state.markerInfo ?
                <div className={"marker-info"}>
                    <ClearIcon onClick={()=>{
                        showMarkerInfo(null);
                    }}/>
                    <img src={state.markerInfo.image?.path || ""}/>
                    <h6>{state.markerInfo.title[lang]}</h6>
                    <span style={{display: "block", textAlign:"center"}}>{state.markerInfo.address}</span>
                    <Button size="small" color="inherit" variant="outlined" onClick={()=>{
                        history.push("/allomas/"+state.markerInfo.id);
                    }}>
                        {translation.common.details}
                    </Button>
                </div>
                : ""
        }
        <Map
            google={google}
            className={"map"}
            zoom={13}
            initialCenter={{
                lat: 47.4962912,
                lng: 19.0661514
            }}
            styles={[
                {
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ebe3cd"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#523735"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#f5f1e6"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#c9b2a6"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#dcd2be"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#ae9e90"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dfd2ae"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dfd2ae"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#93817c"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#a5b076"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#447530"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f1e6"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#fdfcf8"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f8c967"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#e9bc62"
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e98d58"
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#db8555"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#806b63"
                        }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dfd2ae"
                        }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#8f7d77"
                        }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#ebe3cd"
                        }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dfd2ae"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#b9d3c2"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#92998d"
                        }
                    ]
                }
            ]}>
            {
                markerData ?
                    markerData.markers.marker.map( (ea , id) => {
                        if(singleAllomas && singleAllomas*1 === ea.id*1){
                            return <Marker key={id} icon={GreenDot} position={{lat: ea.lat, lng: ea.lng }} onClick={ () => showMarkerInfo(ea)}/>
                        }
                        return <Marker key={id} position={{lat: ea.lat, lng: ea.lng }} onClick={ () => showMarkerInfo(ea)}/>
                    })
                    : ""
            }
        </Map>
    </div>,

    mobile ?
    <AppBar position="static" style={{background:"#fff"}} className={'stickToBottom submenu'} key={"3"}>
        <Tabs
            value={3}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
        >
            <Tab component="a"  icon={<HomeIcon />}/>
            <Tab component="a"  icon={<DirectionsWalkIcon />} disabled={!selectedRoute}/>
            <Tab component="a"  icon={<RoomIcon />} disabled={!singleAllomas}/>
            <Tab component="a"  icon={<MapIcon />}/>
        </Tabs>
    </AppBar> : null

    ]);
}
export default GoogleApiWrapper({
    apiKey: apiKey,
    libraries: []
})(MobileMap);
