import React, {useEffect, useState} from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import useWindowSize from "./WindowSize";
import {getter} from "../localstorage";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import GreenDot from "../img/marker_green.png";
import {useHistory} from "react-router-dom";
/*global google*/

function GoogleMap (props) {
    const route = props.route;
    const {selectedMarker = null} = props;
    let history = useHistory();
    const id = route?.routesById?.id;

    const size = useWindowSize();
    const mobile = size.width < 876;

    const [map, setMap] = useState(null);

    const [state, setState] = useState({
        center:{
            lat: 47.4862912,
            lng: 19.0661514
        },
        directionsService: new google.maps.DirectionsService(),
        directionsDisplay: new google.maps.DirectionsRenderer({suppressMarkers: true, polylineOptions: {
                strokeColor: "#097138"
            }}),
        markerInfo: null,
    })

    useEffect(()=>{
        if(route && map && !props.hidden){


            const markers = route.routesById.route_markers;

            state.directionsDisplay.setMap(null);
            state.directionsDisplay.setMap(map);

            const waypoints = markers.map(item =>{
                return{
                    location: {lat: item.marker.lat * 1, lng:item.marker.lng * 1},
                    stopover: true
                }
            })

            const origin = waypoints.shift().location//.location;
            const destination = waypoints.pop().location//.location;


            state.directionsService.route({
                origin: origin,
                destination: destination,
                waypoints: waypoints,
                travelMode: 'WALKING',
            }, (response, status) => {
                if (status === 'OK') {
                    state.directionsDisplay.setDirections(response);
                } else {
                    //window.alert('Directions request failed due to ' + status);
                }
            });
        }
    },[map, id])

    const handleMapReady = (mapProps, map) =>{
        setMap(map);
    }

    const showMarkerInfo = (markerData) => {
        setState(prevState => {
            return {...prevState, markerInfo: markerData}
        })
    }

    if(map && map.zoom < 4){
        map.setZoom(13);
    }

    return(
            <div className="map-container" style={{height: props.height, width: '100%', position: 'relative'}}>
                <Map
                    google={props.google}
                    className={"map"}
                    zoom={14}
                    initialCenter={state.center}
                    onReady={handleMapReady}
                    styles={[
                        {
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ebe3cd"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#523735"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#f5f1e6"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#c9b2a6"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#dcd2be"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#ae9e90"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.natural",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dfd2ae"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dfd2ae"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#93817c"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#a5b076"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#447530"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f1e6"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#fdfcf8"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f8c967"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#e9bc62"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway.controlled_access",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e98d58"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway.controlled_access",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#db8555"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#806b63"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dfd2ae"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#8f7d77"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#ebe3cd"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dfd2ae"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#b9d3c2"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#92998d"
                                }
                            ]
                        }
                    ]}>
                    {
                        route ?
                        route.routesById.route_markers.map( (e, i) => {
                            if(e.hasOwnProperty('helper') && e.helper){
                                //return (<Marker key={i} icon={"https://maps.google.com/mapfiles/ms/icons/green-dot.png"} position={{lat: e.lat, lng: e.lng }} label={{text:(i+1)+'', color:"white"}} />);
                            }
                            if(e.id*1 === selectedMarker*1){
                                return  (<Marker key={i+"-marker"} icon={GreenDot} position={{lat: e.marker.lat, lng: e.marker.lng }} label={{text:(i+1)+'', color:"white"}} onClick={ () => showMarkerInfo(e) }  />);
                            }
                            return (<Marker key={i+"-marker"} position={{lat: e.marker.lat, lng: e.marker.lng }} label={{text:(i+1)+'', color:"white"}} onClick={ () => showMarkerInfo(e) }  />);
                        })
                            : ""
                    }
                </Map>
                {
                    state.markerInfo ?
                        <div className={"marker-info"}>
                            <ClearIcon onClick={()=>{
                                showMarkerInfo(null);
                            }}/>
                            <img src={state.markerInfo.marker.markerImage?.path || ""}/>
                            <h6>{state.markerInfo.marker.title.hu}</h6>
                            <span style={{display: "block", textAlign:"center"}}>{state.markerInfo.address}</span>
                            <Button size="small" color="inherit" variant="outlined" onClick={()=>{
                                if(props.selectedRoute){
                                    if(props.reference){
                                        window.scrollTo(0, props.reference.current.offsetTop);
                                        history.push("/allomas/"+props.selectedRoute+"/"+state.markerInfo.id);
                                    }else{
                                        history.push("/allomas/"+props.selectedRoute+"/"+state.markerInfo.id);
                                    }
                                }
                                if(!mobile && props.route){
                                    history.push("/allomas/"+props.route.routesById.id+"/"+state.markerInfo.id)
                                }
                            }} >
                                Részletek
                            </Button>
                        </div>
                        : ""
                }

            </div>
        );

}

export default GoogleApiWrapper({
    apiKey: "AIzaSyCdaxK4PJGM5z8moAflzo67BztLfZfnkyM",
    libraries: []
})(GoogleMap);
