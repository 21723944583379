import React from 'react';
import { Link } from 'react-router-dom';
import PageNotFound from '../img/404.png'
import Button from "@material-ui/core/Button";
function NotFoundPage() {
    return <div style={{marginTop: "90px",flexDirection: "column",height: "calc(100vh - 90px) ", display:"flex", justifyContent: "center", alignItems: "center", background:"#fff"}}>
        <img src={PageNotFound}  style={{maxWidth: "100%"}}/>
        <Button variant="contained" color="primary" style={{textAlign:"center"}}>
            <Link style={{color: "white", textDecoration: "none"}} to="/">Go to Home </Link>
        </Button>
    </div>;
}

export default NotFoundPage;
