export const apiKey = "AIzaSyCdaxK4PJGM5z8moAflzo67BztLfZfnkyM";


export const difficulty = [
    { id: 1, key: "1", value: "1", hu: "Gyerekeknek is ajánlott", en: "Child friendly" },
    { id: 2, key: "2", value: "2", hu: "Akadálymentesített", en: "Accessible for handicapped" },
    { id: 3, key: "3", value: "3", hu: "Vezetett séta", en: "Guided tour" },
    { id: 4, key: "4", value: "4", hu: "Kezdőknek", en: "Beginner tour"},
    { id: 5, key: "5", value: "5", hu: "Haladóknak", en: "Advanced tour" },
    { id: 6, key: "6", value: "6", hu: "Bringával ajánlott", en: "By bike" },
];
