import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Redirect, Switch, Route} from "react-router-dom";
import Home from "./pages/Home";

import Layout from "./components/Layout";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import useWindowSize from "./components/WindowSize";
import Utvonal from "./pages/Utvonal";
import Allomas from "./pages/Allomas";
import MobileMap from "./pages/MobileMap";
import SingleAllomas from "./pages/SingleAllomas";
import {getTranslation} from "./translate";
import NotFoundPage from "./pages/NotFoundPage";

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            /*'Nunito',*/
            /*'Roboto',*/
            /*'"Helvetica Neue"',*/
            /*'Arial',*/
            /*'sans-serif'*/
        ].join(','),
    },
    palette: {
        primary:{
            main: '#07a8cd'
        },
        secondary: {
            main: '#fff'
        }
    }
});



function App() {

    const size = useWindowSize();
    const mobile = size.width < 876;

    const [state, setState] = useState({
        selectedRoute: null,
        selectedMarker: null,
        singleAllomas: null,
        lang: "hu"
    });

    const translation = getTranslation(state.lang);

    const setRoute = (route) => {
        if(state.selectedRoute !== route){
            setState(prevState => {
                return {...prevState, selectedRoute: route, selectedMarker: null, singleAllomas: null}
            })
        }else{
            setState(prevState => {
                return {...prevState, selectedRoute: route}
            })
        }
    }

    const setMarker = (marker) => {
        setState(prevState => {
            return {...prevState, selectedMarker: marker}
        })
    }

    const setSingleAllomas = (marker) => {
        setState(prevState => {
            return {...prevState, singleAllomas: marker}
        })
    }

    const setLang = (lang) => {
        setState(prevState => {
            return {...prevState, lang: lang}
        })
    }
    return (
        <div className="App">
            <MuiThemeProvider theme={theme}>
                <Router>
                    <Layout mobile={mobile} translation={translation} lang={state.lang} setLang={setLang}>
                        <Switch>
                            <Route path={"/"} exact={true}  render={(props) => {
                                return ( <Home {...props} lang={state.lang} translation={translation} mobile={mobile} selectedRoute={state.selectedRoute} selectedMarker={state.selectedMarker} singleAllomas={state.singleAllomas}/> )
                            }} />/>
                            <Route path='/route/:id' render={(props) => {
                                return ( <Utvonal lang={state.lang} translation={translation} {...props } mobile={mobile} setRoute={setRoute} selectedMarker={state.selectedMarker}/> )
                            }} />
                            <Route path='/allomas/:id/:route_marker' render={(props) => {
                                return ( <Allomas lang={state.lang} translation={translation} {...props } mobile={mobile} setMarker={setMarker} selectedRoute={state.selectedRoute} selectedMarker={state.selectedMarker} setRoute={setRoute}/> )
                            }} />
                            <Route path='/map' render={(props) => {
                                return ( <MobileMap lang={state.lang} translation={translation} {...props } mobile={mobile} selectedRoute={state.selectedRoute} selectedMarker={state.selectedMarker} singleAllomas={state.singleAllomas}/> )
                            }} />
                            <Route path='/route-map' render={(props) => {
                                return ( <MobileMap lang={state.lang} translation={translation} {...props } mobile={mobile} selectedRoute={state.selectedRoute} selectedMarker={state.selectedMarker} route={true} singleAllomas={state.singleAllomas}/> )
                            }} />
                            <Route path='/allomas/:id' render={(props) => {
                                return ( <SingleAllomas lang={state.lang} translation={translation} {...props } mobile={mobile} selectedRoute={state.selectedRoute} singleAllomas={state.singleAllomas} setSingleAllomas={setSingleAllomas}/> )
                            }} />
                            <Route path="/404" component={NotFoundPage} />
                            <Redirect to="/404" />
                        </Switch>
                    </Layout>
                </Router>
            </MuiThemeProvider>
        </div>
    );
}

export default App;
