import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {fade} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from '@material-ui/icons/Search';
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {GET_MARKERS, GET_ROUTE_SEARCH_RESULT} from "../graphql/graphql";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import HeightIcon from '@material-ui/icons/Height';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import {difficulty, apiKey} from "../const";
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";
import ClearIcon from '@material-ui/icons/Clear';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MapIcon from '@material-ui/icons/Map';
import RoomIcon from '@material-ui/icons/Room';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import HomeIcon from '@material-ui/icons/Home';
import CircularProgress from "@material-ui/core/CircularProgress";
import {Alert} from "@material-ui/lab";
import video from "../img/output_KtSGsI.mp4"
import transitions from "@material-ui/core/styles/transitions";
import {GApageView} from "../index";
/*global google*/

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: '10px',
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        width: '100%',
        height:'42px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
        border: '1px solid #a9a9a9',
        '&:focus':{
            border: '1px solid #000',
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        color: '#a9a9a9',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right:0,
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 1),
        // vertical padding + font size from searchIcon
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        height:'28px',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
        '&::placeholder': {
            color: '#a9a9a9',
            fontSize: '1.0em',
            fontFamily: "'Open Sans', italic"
        },
    },
    root: {
        width: '360px',
        height: '660px',
        margin: "15px 15px",
        borderRadius: '20px',
        display: "flex",
        flexDirection: "column",
    },
    media: {
        height: 260,
    },
}));
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 100)
const getWords = (str) => {
    return str.split(/\s+/).slice(0,20).join(" ").substr(0, 399);
}

function Home(props) {
    const [state, setState] = useState({
       markerInfo : null,
    });

    const classes = useStyles();
    const ref = React.createRef();
    let history = useHistory();

    //kereséshez lazy loading használata paraméternek a input tartalma
    const [getRoutes, { data, loading }] = useLazyQuery(GET_ROUTE_SEARCH_RESULT);

    useEffect(()=> {
        GApageView(window.location.href);
        getRoutes({
            variables:{
                limit: 100,
                active: true,
            }
        });
    }, [])

    const { data: markerData, loading: markerLoading, error:markerError } = useQuery(GET_MARKERS);

    const showMarkerInfo = (markerData) => {
        setState(prevState => {
            return {...prevState, markerInfo: markerData}
        })
    }

    const handleChange = (event, newValue) => {
        switch(newValue){
            case 1 :
                history.push("/route/"+props.selectedRoute);
                break;
            case 2 :
                if( props.singleAllomas ){
                    history.push("/allomas/"+props.singleAllomas);
                }else{
                    history.push("/allomas/"+props.selectedRoute+"/"+props.selectedMarker);
                }
                break;
            case 3 :
                if(props.selectedRoute){
                    history.push("/route-map");
                }else{
                    history.push("/map");
                }
                break;
        }
    };
    /**
     * útvonal keresés min3 leütés után keres
     * @param e input event
     */
    const searchInputChange = (e) => {
        if(e.target.value.length > 2){
            getRoutes({
                variables:{
                    like: e.target.value,
                    active: true
                }
            });
        }
        if(e.target.value.length === 0){
            getRoutes();
        }
    }

    //ami a query töltődik addig töltő képernyő

    return (
        [
            <div className="header-banner" key={"1"}>
                <div className={"dark-bg"}/>
                <video autoPlay muted loop  playsInline id="myVideo">
                    <source src={video} type="video/mp4"/>
                        Your browser does not support HTML5 video.
                </video>
                <Typography variant="h1" component="h1">
                    {props.translation.home.title}
                </Typography>
                <Typography variant="body1" component="p">
                    {props.translation.home.subtitle}
                </Typography>
                <Button variant="contained" onClick={()=>{
                    scrollToRef(ref);
                }}>
                    {props.translation.home.btn1}
                </Button>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule={"evenodd"} clipRule={"evenodd"} d="M10.4535 22.8286C10.558 22.7238 10.6821 22.6407 10.8188 22.584C10.9555 22.5272 11.102 22.4981 11.25 22.4981C11.398 22.4981 11.5445 22.5272 11.6812 22.584C11.8178 22.6407 11.942 22.7238 12.0465 22.8286L18 28.7843L23.9535 22.8286C24.0581 22.724 24.1823 22.641 24.3189 22.5844C24.4556 22.5278 24.6021 22.4986 24.75 22.4986C24.8979 22.4986 25.0444 22.5278 25.1811 22.5844C25.3177 22.641 25.4419 22.724 25.5465 22.8286C25.6511 22.9332 25.7341 23.0573 25.7907 23.194C25.8473 23.3307 25.8764 23.4771 25.8764 23.6251C25.8764 23.773 25.8473 23.9195 25.7907 24.0561C25.7341 24.1928 25.6511 24.317 25.5465 24.4216L18.7965 31.1716C18.692 31.2763 18.5678 31.3595 18.4312 31.4162C18.2945 31.4729 18.148 31.5021 18 31.5021C17.852 31.5021 17.7055 31.4729 17.5688 31.4162C17.4321 31.3595 17.308 31.2763 17.2035 31.1716L10.4535 24.4216C10.3487 24.3171 10.2656 24.1929 10.2089 24.0562C10.1522 23.9196 10.123 23.773 10.123 23.6251C10.123 23.4771 10.1522 23.3306 10.2089 23.1939C10.2656 23.0572 10.3487 22.9331 10.4535 22.8286Z" fill="white"/>
                    <path fillRule={"evenodd"} clipRule={"evenodd"} d="M18 30.375C17.7016 30.375 17.4155 30.2565 17.2045 30.0455C16.9935 29.8345 16.875 29.5484 16.875 29.25L16.875 5.625C16.875 5.32663 16.9935 5.04048 17.2045 4.8295C17.4155 4.61853 17.7016 4.5 18 4.5C18.2984 4.5 18.5845 4.61853 18.7955 4.8295C19.0065 5.04048 19.125 5.32663 19.125 5.625L19.125 29.25C19.125 29.5484 19.0065 29.8345 18.7955 30.0455C18.5845 30.2565 18.2984 30.375 18 30.375Z" fill="white"/>
                </svg>
            </div>,
            <Container maxWidth="lg" key={"2"} style={{padding:  props.mobile ? "20px 0 40px" : "100px 0"}}  ref={ref}>
                <Typography variant="h2" component="h2" className={"header-2"}>
                    {props.translation.home.title2}
                </Typography>
                <div style={{display: "flex", justifyContent: "center", margin: "48px 0 90px 0"}} className={"search-container"}>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder={props.translation.home.label1}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}

                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e)=>{
                                searchInputChange(e);
                            }}
                        />
                    </div>
                </div>
                <div className={"result-list"}>
                    <div style={{flexGrow: "1"}}>
                        <Grid container spacing={3} className={"result-container"}>
                            {
                                loading ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}} ><CircularProgress /></div>
                                    :
                                    data ?
                                        data.routes.count === 0 ?
                                            <div style={{margin:"30px 5px"}}><Alert severity="info">{props.translation.home.noresult}</Alert></div>
                                            :
                                            data.routes.route.map( (ea, id) => {
                                    return (
                                        <Card className={classes.root} key={"card-"+id}>
                                            <CardActionArea onClick={()=> {
                                                window.gtag_report_conversion();
                                                history.push("/route/"+ea.id)
                                            }} >
                                                <CardMedia
                                                    className={classes.media}
                                                    image={ea.image?.path}
                                                    title={ea.title[props.lang]}
                                                />
                                                <CardContent style={{padding: "0px 20px"}}>
                                                    <Typography gutterBottom variant="h2" component="h2" className={"card-header-2"}>
                                                        {ea.title.hu}
                                                    </Typography>
                                                    <div className={"card-text"}
                                                        dangerouslySetInnerHTML={{ __html: getWords(ea.description[props.lang].replace(/<[^>]*>/g, ''))+"..."}}
                                                    />
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions style={{flexDirection: "column", flexGrow: "1", justifyContent: "flex-end", padding: "20px", paddingTop: "0"}}>
                                                <div className={"card-info"}>
                                                    <div>
                                                        <div className={"card-info-item"}>
                                                            {/*<AccessTimeIcon/>*/}
                                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M11.5 23C5.15844 23 0 17.8402 0 11.5C0 5.15978 5.15844 0 11.5 0C17.8416 0 23 5.15844 23 11.5C23 17.8416 17.8402 23 11.5 23ZM11.5 0.801208C5.60044 0.801208 0.801208 5.60044 0.801208 11.5C0.801208 17.3996 5.60044 22.1988 11.5 22.1988C17.3996 22.1988 22.1988 17.3996 22.1988 11.5C22.1988 5.60044 17.3982 0.801208 11.5 0.801208Z" fill="black"/>
                                                                <path d="M16.4862 16.8865C16.3834 16.8865 16.2806 16.8477 16.2031 16.7689L11.0994 11.6653V6.52951C11.0994 6.30784 11.2784 6.12891 11.5 6.12891C11.7217 6.12891 11.9006 6.30784 11.9006 6.52951V11.3341L16.7706 16.2041C16.9269 16.3603 16.9269 16.614 16.7706 16.7703C16.6919 16.8477 16.589 16.8865 16.4862 16.8865Z" fill="black"/>
                                                            </svg>

                                                            <span>{ea.duration ? ea.duration + " " + props.translation.common.hour : "-"}</span>
                                                        </div>
                                                        <div className={"card-info-item"}>
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M17.9282 22.0001H12.5241C12.3005 22.0001 12.12 21.8339 12.12 21.628C12.12 21.4221 12.3005 21.2559 12.5241 21.2559H17.9282C19.7277 21.2559 21.1919 19.9077 21.1919 18.2506C21.1919 16.5935 19.7277 15.2452 17.9282 15.2452H4.92454C2.67915 15.2452 0.852661 13.5633 0.852661 11.4957C0.852661 9.428 2.67915 7.74609 4.92454 7.74609H13.2057C13.4293 7.74609 13.6098 7.9123 13.6098 8.1182C13.6098 8.32409 13.4293 8.4903 13.2057 8.4903H4.92454C3.12499 8.4903 1.66084 9.83856 1.66084 11.4957C1.66084 13.1528 3.12499 14.4998 4.92454 14.4998H17.9282C20.1735 14.4998 22 16.1817 22 18.2493C22 20.317 20.1735 22.0001 17.9282 22.0001Z" fill="black"/>
                                                                <path d="M16.3333 3.94064C16.9106 3.94064 17.3786 3.50971 17.3786 2.97813C17.3786 2.44655 16.9106 2.01562 16.3333 2.01562C15.7561 2.01562 15.2881 2.44655 15.2881 2.97813C15.2881 3.50971 15.7561 3.94064 16.3333 3.94064Z" fill="black"/>
                                                                <path d="M16.3333 8.48892C16.0303 8.48892 15.7514 8.34876 15.5858 8.11558C14.4045 6.44855 12.9955 4.21965 12.9955 3.07358C12.9942 1.37926 14.492 0 16.3333 0C18.1746 0 19.6725 1.37926 19.6725 3.07358C19.6725 4.21965 18.2635 6.44731 17.0822 8.11558C16.9152 8.35 16.6364 8.48892 16.3333 8.48892ZM16.3333 0.744207C14.9379 0.744207 13.8037 1.78982 13.8037 3.07358C13.8037 3.8029 14.7224 5.53566 16.2619 7.70875C16.2835 7.73851 16.3118 7.74471 16.3333 7.74471C16.3549 7.74471 16.3832 7.73851 16.4047 7.70875C17.9443 5.53566 18.8629 3.8029 18.8629 3.07358C18.8629 1.78982 17.7288 0.744207 16.3333 0.744207Z" fill="black"/>
                                                                <path d="M9.81263 22.0001H8.39292C8.16932 22.0001 7.98883 21.8339 7.98883 21.628C7.98883 21.4221 8.16932 21.2559 8.39292 21.2559H9.81263C10.0362 21.2559 10.2167 21.4221 10.2167 21.628C10.2167 21.8339 10.0349 22.0001 9.81263 22.0001Z" fill="black"/>
                                                                <path d="M5.81756 22.0001H4.39921C4.17561 22.0001 3.99512 21.8339 3.99512 21.628C3.99512 21.4221 4.17561 21.2559 4.39921 21.2559H5.81891C6.04251 21.2559 6.223 21.4221 6.223 21.628C6.223 21.8339 6.04116 22.0001 5.81756 22.0001Z" fill="black"/>
                                                                <path d="M1.82379 22.0001H0.40409C0.180493 22.0001 0 21.8339 0 21.628C0 21.4221 0.180493 21.2559 0.40409 21.2559H1.82379C2.04739 21.2559 2.22788 21.4221 2.22788 21.628C2.22788 21.8339 2.04604 22.0001 1.82379 22.0001Z" fill="black"/>
                                                            </svg>
                                                            {/*<HeightIcon style={{transform: "rotate(90deg)"}}/>*/}
                                                            <span>{ea.distance ? ea.distance +" km" : "-"}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={"card-info-item"}>
                                                            <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0)">
                                                                    <path d="M12 22C6.18221 22 0 20.8319 0 18.6678C0 16.9782 3.58681 16.1457 5.72505 15.8001C5.93796 15.7654 6.14042 15.9017 6.17699 16.1036C6.21356 16.3055 6.06988 16.4975 5.85697 16.5322C2.35245 17.0996 0.783716 18.0138 0.783716 18.6678C0.783716 19.7492 5.05105 21.2568 12 21.2568C18.9489 21.2568 23.2163 19.7492 23.2163 18.6678C23.2163 17.5889 19.0521 16.085 12.2717 16.085C12.0549 16.085 11.8798 15.919 11.8798 15.7134C11.8798 15.5078 12.0549 15.3418 12.2717 15.3418C17.9575 15.3418 24 16.5074 24 18.6678C24 20.8319 17.8178 22 12 22Z" fill="black"/>
                                                                    <path d="M10.2667 21.2345H9.48297V0.371626C9.48297 0.239081 9.55742 0.116446 9.6789 0.0495539C9.80038 -0.017338 9.95059 -0.0160993 10.0708 0.0507926L18.7987 4.86205C18.9189 4.92895 18.9934 5.05158 18.9934 5.18289C18.9934 5.31419 18.9189 5.43807 18.7987 5.50372L10.2667 10.2085V21.2345ZM10.2667 1.01701V9.34877L17.823 5.18289L10.2667 1.01701Z" fill="black"/>
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0">
                                                                        <rect width="24" height="22" fill="white"/>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            {/*<EmojiFlagsIcon/>*/}
                                                            <span>{ea.marker_count + " " + props.translation.home.station}</span>
                                                        </div>
                                                        <div className={"card-info-item"}>
                                                            {/*<AccessibilityNewIcon/>*/}
                                                            <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.1205 27.0008C12.316 27.0008 11.6627 26.3416 11.6627 25.53V16.3045H10.3373V25.53C10.3373 26.3416 9.68398 27.0008 8.87952 27.0008C8.07506 27.0008 7.42169 26.3416 7.42169 25.53V9.88678H1.45783C0.653373 9.88678 0 9.22762 0 8.41605C0 7.60447 0.653373 6.94531 1.45783 6.94531H20.5422C21.3466 6.94531 22 7.60447 22 8.41605C22 9.22762 21.3466 9.88678 20.5422 9.88678H14.5783V25.53C14.5783 26.3416 13.9249 27.0008 13.1205 27.0008ZM9.54217 15.5023H12.4578V25.53C12.4578 25.8991 12.7547 26.1986 13.1205 26.1986C13.4863 26.1986 13.7831 25.8991 13.7831 25.53V9.08456H20.5422C20.908 9.08456 21.2048 8.78507 21.2048 8.41605C21.2048 8.04703 20.908 7.74753 20.5422 7.74753H1.45783C1.09205 7.74753 0.795181 8.04703 0.795181 8.41605C0.795181 8.78507 1.09205 9.08456 1.45783 9.08456H8.21687V25.53C8.21687 25.8991 8.51373 26.1986 8.87952 26.1986C9.2453 26.1986 9.54217 25.8991 9.54217 25.53V15.5023Z" fill="black"/>
                                                                <path d="M11 5.61553C9.46529 5.61553 8.21686 4.35605 8.21686 2.80776C8.21686 1.25948 9.46529 0 11 0C12.5347 0 13.7831 1.25948 13.7831 2.80776C13.7831 4.35605 12.5347 5.61553 11 5.61553ZM11 0.802218C9.90397 0.802218 9.01204 1.70204 9.01204 2.80776C9.01204 3.91349 9.90397 4.81331 11 4.81331C12.096 4.81331 12.9879 3.91349 12.9879 2.80776C12.9879 1.70204 12.096 0.802218 11 0.802218Z" fill="black"/>
                                                            </svg>
                                                            <span>{ea.difficulty ? difficulty[ea.difficulty*1-1][props.lang] : "-"}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Button size="small" color="inherit" variant="outlined" onClick={()=>{
                                                    window.gtag_report_conversion();
                                                    history.push("/route/"+ea.id)
                                                }}>
                                                    {props.translation.common.show}
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    );
                                })
                                    : ""
                            }
                        </Grid>
                    </div>
                    {
                        props.mobile ?
                            null
                            :
                            <div style={{margin: "120px auto 0", position:"relative", maxWidth: "1140px", height: "400px"}}>
                                {
                                    state.markerInfo ?
                                        <div className={"marker-info"}>
                                            <ClearIcon onClick={()=>{
                                                showMarkerInfo(null);
                                            }}/>
                                            <img src={state.markerInfo.image?.path || ""}/>
                                            <h6>{state.markerInfo.title[props.lang]}</h6>
                                            <span style={{display: "block", textAlign:"center"}}>{state.markerInfo.address}</span>
                                            <Button size="small" color="inherit" variant="outlined" onClick={()=>{
                                                history.push("/allomas/"+state.markerInfo.id);
                                            }}>
                                                {props.translation.common.details}
                                            </Button>
                                        </div>
                                        : ""
                                }
                                <Map
                                    google={props.google}
                                    className={"map"}
                                    zoom={13}
                                    initialCenter={{
                                        lat: 47.4962912,
                                        lng: 19.0661514
                                    }}
                                    styles={[
                                        {
                                            "elementType": "geometry",
                                            "stylers": [
                                                {
                                                    "color": "#ebe3cd"
                                                }
                                            ]
                                        },
                                        {
                                            "elementType": "labels.text.fill",
                                            "stylers": [
                                                {
                                                    "color": "#523735"
                                                }
                                            ]
                                        },
                                        {
                                            "elementType": "labels.text.stroke",
                                            "stylers": [
                                                {
                                                    "color": "#f5f1e6"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "administrative",
                                            "elementType": "geometry.stroke",
                                            "stylers": [
                                                {
                                                    "color": "#c9b2a6"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "administrative.land_parcel",
                                            "elementType": "geometry.stroke",
                                            "stylers": [
                                                {
                                                    "color": "#dcd2be"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "administrative.land_parcel",
                                            "elementType": "labels.text.fill",
                                            "stylers": [
                                                {
                                                    "color": "#ae9e90"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "landscape.natural",
                                            "elementType": "geometry",
                                            "stylers": [
                                                {
                                                    "color": "#dfd2ae"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "poi",
                                            "elementType": "geometry",
                                            "stylers": [
                                                {
                                                    "color": "#dfd2ae"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "poi",
                                            "elementType": "labels.text.fill",
                                            "stylers": [
                                                {
                                                    "color": "#93817c"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "poi.park",
                                            "elementType": "geometry.fill",
                                            "stylers": [
                                                {
                                                    "color": "#a5b076"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "poi.park",
                                            "elementType": "labels.text.fill",
                                            "stylers": [
                                                {
                                                    "color": "#447530"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "road",
                                            "elementType": "geometry",
                                            "stylers": [
                                                {
                                                    "color": "#f5f1e6"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "road.arterial",
                                            "elementType": "geometry",
                                            "stylers": [
                                                {
                                                    "color": "#fdfcf8"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "road.highway",
                                            "elementType": "geometry",
                                            "stylers": [
                                                {
                                                    "color": "#f8c967"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "road.highway",
                                            "elementType": "geometry.stroke",
                                            "stylers": [
                                                {
                                                    "color": "#e9bc62"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "road.highway.controlled_access",
                                            "elementType": "geometry",
                                            "stylers": [
                                                {
                                                    "color": "#e98d58"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "road.highway.controlled_access",
                                            "elementType": "geometry.stroke",
                                            "stylers": [
                                                {
                                                    "color": "#db8555"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "road.local",
                                            "elementType": "labels.text.fill",
                                            "stylers": [
                                                {
                                                    "color": "#806b63"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "transit.line",
                                            "elementType": "geometry",
                                            "stylers": [
                                                {
                                                    "color": "#dfd2ae"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "transit.line",
                                            "elementType": "labels.text.fill",
                                            "stylers": [
                                                {
                                                    "color": "#8f7d77"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "transit.line",
                                            "elementType": "labels.text.stroke",
                                            "stylers": [
                                                {
                                                    "color": "#ebe3cd"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "transit.station",
                                            "elementType": "geometry",
                                            "stylers": [
                                                {
                                                    "color": "#dfd2ae"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "water",
                                            "elementType": "geometry.fill",
                                            "stylers": [
                                                {
                                                    "color": "#b9d3c2"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "water",
                                            "elementType": "labels.text.fill",
                                            "stylers": [
                                                {
                                                    "color": "#92998d"
                                                }
                                            ]
                                        }
                                    ]}>
                                    {
                                        markerData ?
                                            markerData.markers.marker.map( (ea , id) => {
                                                return <Marker key={id} position={{lat: ea.lat, lng: ea.lng }} onClick={ () => showMarkerInfo(ea)}/>
                                            })
                                            : ""
                                    }
                                </Map>
                            </div>
                    }
                </div>
            </Container>,

                props.mobile ?
                    <AppBar position="static" style={{background:"#fff"}} className={'stickToBottom submenu'} key={"3"}>
                        <Tabs
                            value={0}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab component="a"  icon={<HomeIcon />}/>
                            <Tab component="a"  icon={<DirectionsWalkIcon />} disabled={!props.selectedRoute}/>
                            {
                                props.singleAllomas ?
                                    <Tab component="a"  icon={<RoomIcon />} />
                                    : <Tab component="a"  icon={<RoomIcon />} disabled={!props.selectedMarker || !props.selectedRoute}/>
                            }
                            <Tab component="a"  icon={<MapIcon />}/>
                        </Tabs>
                    </AppBar>
                    : null


        ]
    );
}

export default GoogleApiWrapper({
    apiKey: apiKey,
    libraries: []
})(Home);

