import React, {useEffect} from 'react';

import {Alert} from "@material-ui/lab";
import {useHistory} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {GET_MARKERS, GET_ROUTE_BY_ID, GET_ROUTE_MARKER_ID} from "../graphql/graphql";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import GoogleMap from "../components/GoogleMap";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import RoomIcon from "@material-ui/icons/Room";
import MapIcon from "@material-ui/icons/Map";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HomeIcon from '@material-ui/icons/Home';
import SwipeableViews from 'react-swipeable-views';
import Pagination from "../components/Pagination";
import ClearIcon from "@material-ui/icons/Clear";
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";
import {apiKey} from "../const";
import {GApageView} from "../index";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(10),
        right: theme.spacing(2),
    },
    fabb: {
        position: 'fixed',
        bottom: theme.spacing(10),
        left: theme.spacing(2),
    }
}));


const scrollToRef = (ref) => {
    window.scrollTo(0, ref.current.offsetTop)
}
/*global google*/
function SingleAllomas({match , mobile, singleAllomas, setSingleAllomas, selectedRoute, google, lang, translation}) {
    const marker_id = match.params.id;
    let history = useHistory();

    const [ getMarker, { data, loading, error }] = useMutation(GET_ROUTE_MARKER_ID, {
        variables: {
            id: marker_id*1
        }
    });

    useEffect(()=>{
        GApageView(window.location.href);
        getMarker();
        setSingleAllomas(marker_id);
    }, [marker_id])

    const handleChange = (event, newValue) => {
        switch(newValue){
            case 0 :
                history.push("/")
                break;
            case 1 :
                history.push("/route/"+selectedRoute);
                break;
            case 3 :
                history.push("/map")
                break;
        }
    };

    if(!data && !loading || error){
        return (<div style={{margin:"30px 5px"}}><Alert severity="info">Az állomás nem található!</Alert></div>);
    }
    if(loading){
        return <div>loading...</div>
    }


    if(mobile){
        return ([<div key={"1"}>
                    <div className="header-banner" key={"1"} style={{backgroundImage: "url("+ data.markersById.image.path +")",height: "50vh"}}>
                        <div className={"dark-bg"} />
                    </div>
                    <Container maxWidth="lg" key={"2"} style={{padding: "0 0 60px",}}>
                        <Typography variant="h2" component="h2" className={"header-2"} style={{textAlign:"left", marginTop: "48px"}}>
                            {data.markersById.title[lang]}
                        </Typography>
                        <div dangerouslySetInnerHTML={{__html: data.markersById.description[lang]}} className={"route-description"}/>
                        <div className={"marker-media"}>
                            <img src={data.markersById.image.path}/>
                        </div>
                    </Container>
                </div>,
                <AppBar position="static" style={{background:"#fff"}} className={'stickToBottom submenu'} key={"2"}>
                    <Tabs
                        value={2}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab component="a" icon={<HomeIcon />}/>
                        <Tab component="a"  icon={<DirectionsWalkIcon />} disabled={!selectedRoute}/>
                        <Tab component="a"  icon={<RoomIcon />}/>
                        <Tab component="a"   icon={<MapIcon />}/>
                    </Tabs>
                </AppBar>]);
    }

    return ([
        <div className="header-banner" key={"1"} style={{backgroundImage: "url("+ data.markersById.image.path +")",height: "75vh"}}>
            <div className={"dark-bg"} />
        </div>,
        <Container maxWidth="lg" key={"2"} style={{padding: "60px 0",}}>
                <Typography variant="h2" component="h2" className={"header-2"} style={{textAlign:"left", marginTop: "48px"}}>
                    {data.markersById.title[lang]}
                </Typography>
                <div dangerouslySetInnerHTML={{__html: data.markersById.description[lang]}} className={"route-description"}/>
                <div className={"marker-media"}>
                    <img src={data.markersById.image.path}/>
                </div>
        </Container>,
        <div style={{margin: "40px 0", position:"relative", width: "100%", height: "400px"}} key={"3"}>
            <Map
                google={google}
                className={"map"}
                zoom={13}
                initialCenter={{
                    lat: data.markersById.lat,
                    lng: data.markersById.lng
                }}
                styles={[
                    {
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ebe3cd"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#523735"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#f5f1e6"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#c9b2a6"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#dcd2be"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#ae9e90"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.natural",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dfd2ae"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dfd2ae"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#93817c"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#a5b076"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#447530"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f1e6"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#fdfcf8"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f8c967"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#e9bc62"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway.controlled_access",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#e98d58"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway.controlled_access",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#db8555"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#806b63"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dfd2ae"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#8f7d77"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#ebe3cd"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dfd2ae"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#b9d3c2"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#92998d"
                            }
                        ]
                    }
                ]}>
                    <Marker  position={{lat: data.markersById.lat, lng: data.markersById.lng }} />
            </Map>
        </div>
    ]);

}
export default GoogleApiWrapper({
    apiKey: apiKey,
    libraries: []
})(SingleAllomas);

