import React, {useEffect} from 'react';
import { Alert } from '@material-ui/lab';
import Typography from "@material-ui/core/Typography";

import {useMutation} from "@apollo/react-hooks";
import {GET_ROUTE_BY_ID} from "../graphql/graphql";
import Container from "@material-ui/core/Container";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import HeightIcon from "@material-ui/icons/Height";
import EmojiFlagsIcon from "@material-ui/icons/EmojiFlags";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import {difficulty} from "../const";
import Button from "@material-ui/core/Button";
import GoogleMap from "../components/GoogleMap";
import {useHistory} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import RoomIcon from "@material-ui/icons/Room";
import MapIcon from "@material-ui/icons/Map";
import HomeIcon from '@material-ui/icons/Home';
import {Map} from "google-maps-react";
import {GApageView} from "../index";

function Utvonal({match, mobile, setRoute, selectedMarker, lang, translation}) {

    const id = match.params.id;
    let history = useHistory();

    const [ getRoute, { data, loading, error }] = useMutation(GET_ROUTE_BY_ID, {
        variables: {
            id: id*1
        }
    });

    useEffect(()=>{
        GApageView(window.location.href);
        getRoute();
        setRoute(id*1);
    }, [id])


    const handleChange = (event, newValue) => {
        switch(newValue){
            case 0 :
                history.push("/")
                break;
            case 2 :
                history.push("/allomas/"+id+"/"+selectedMarker)
                break;
            case 3 :
                history.push("/route-map")
                break;
        }
    };

    if(!data && !loading || error){
        return (<div style={{margin:"30px 5px"}}><Alert severity="info">{translation.routes.noresult}</Alert></div>);
    }
    if(loading){
        return <div>loading...</div>
    }
    return ([
            <div className="header-banner" key={"1"} style={{backgroundImage: "url("+ data.routesById.image.path +")",height: mobile ? "250px" : "560px"}}>
                <div className={"dark-bg"} />
            </div>,
            <Container maxWidth="lg" key={"2"} style={{padding: mobile ? "35px 0 0" : "75px 0 0",}}>
                <div className={"back-to-home"}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.78088 6.969C8.85072 7.03867 8.90614 7.12143 8.94394 7.21255C8.98175 7.30366 9.00122 7.40135 9.00122 7.5C9.00122 7.59865 8.98175 7.69633 8.94394 7.78745C8.90614 7.87857 8.85072 7.96133 8.78088 8.031L4.81038 12L8.78088 15.969C8.85061 16.0387 8.90592 16.1215 8.94366 16.2126C8.9814 16.3037 9.00082 16.4014 9.00082 16.5C9.00082 16.5986 8.9814 16.6963 8.94366 16.7874C8.90592 16.8785 8.85061 16.9613 8.78088 17.031C8.71114 17.1007 8.62836 17.156 8.53725 17.1938C8.44614 17.2315 8.34849 17.2509 8.24988 17.2509C8.15126 17.2509 8.05361 17.2315 7.9625 17.1938C7.87139 17.156 7.78861 17.1007 7.71888 17.031L3.21888 12.531C3.14903 12.4613 3.09362 12.3786 3.05581 12.2874C3.018 12.1963 2.99854 12.0986 2.99854 12C2.99854 11.9013 3.018 11.8037 3.05581 11.7125C3.09362 11.6214 3.14903 11.5387 3.21888 11.469L7.71888 6.969C7.78854 6.89915 7.87131 6.84374 7.96243 6.80593C8.05354 6.76812 8.15123 6.74866 8.24988 6.74866C8.34853 6.74866 8.44621 6.76812 8.53733 6.80593C8.62844 6.84374 8.71121 6.89915 8.78088 6.969Z" fill="#B2B2B2"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.75 12C3.75 11.8011 3.82902 11.6103 3.96967 11.4697C4.11032 11.329 4.30109 11.25 4.5 11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H4.5C4.30109 12.75 4.11032 12.671 3.96967 12.5303C3.82902 12.3897 3.75 12.1989 3.75 12Z" fill="#B2B2B2"/>
                    </svg>
                    <span onClick={()=>{history.push("/")}}>{translation.common.back}</span>
                </div>
                <Typography variant="h2" component="h2" className={"header-2"} style={{textAlign:"left", marginTop: "48px"}}>
                    {data.routesById.title[lang]}
                </Typography>
               <div className={"route-info"}>
                   <div dangerouslySetInnerHTML={{__html: data.routesById.description[lang]}} className={"route-description"}/>
                   <div className={"route-info-meta"}>
                       <div className={"card-info-item"}>
                           <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M11.5 23C5.15844 23 0 17.8402 0 11.5C0 5.15978 5.15844 0 11.5 0C17.8416 0 23 5.15844 23 11.5C23 17.8416 17.8402 23 11.5 23ZM11.5 0.801208C5.60044 0.801208 0.801208 5.60044 0.801208 11.5C0.801208 17.3996 5.60044 22.1988 11.5 22.1988C17.3996 22.1988 22.1988 17.3996 22.1988 11.5C22.1988 5.60044 17.3982 0.801208 11.5 0.801208Z" fill="black"/>
                               <path d="M16.4862 16.8865C16.3834 16.8865 16.2806 16.8477 16.2031 16.7689L11.0994 11.6653V6.52951C11.0994 6.30784 11.2784 6.12891 11.5 6.12891C11.7217 6.12891 11.9006 6.30784 11.9006 6.52951V11.3341L16.7706 16.2041C16.9269 16.3603 16.9269 16.614 16.7706 16.7703C16.6919 16.8477 16.589 16.8865 16.4862 16.8865Z" fill="black"/>
                           </svg>
                           <span>{data.routesById.duration ? data.routesById.duration + " " + translation.common.hour : "-"}</span>
                       </div>
                       <div className={"card-info-item"}>
                           <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M17.9282 22.0001H12.5241C12.3005 22.0001 12.12 21.8339 12.12 21.628C12.12 21.4221 12.3005 21.2559 12.5241 21.2559H17.9282C19.7277 21.2559 21.1919 19.9077 21.1919 18.2506C21.1919 16.5935 19.7277 15.2452 17.9282 15.2452H4.92454C2.67915 15.2452 0.852661 13.5633 0.852661 11.4957C0.852661 9.428 2.67915 7.74609 4.92454 7.74609H13.2057C13.4293 7.74609 13.6098 7.9123 13.6098 8.1182C13.6098 8.32409 13.4293 8.4903 13.2057 8.4903H4.92454C3.12499 8.4903 1.66084 9.83856 1.66084 11.4957C1.66084 13.1528 3.12499 14.4998 4.92454 14.4998H17.9282C20.1735 14.4998 22 16.1817 22 18.2493C22 20.317 20.1735 22.0001 17.9282 22.0001Z" fill="black"/>
                               <path d="M16.3333 3.94064C16.9106 3.94064 17.3786 3.50971 17.3786 2.97813C17.3786 2.44655 16.9106 2.01562 16.3333 2.01562C15.7561 2.01562 15.2881 2.44655 15.2881 2.97813C15.2881 3.50971 15.7561 3.94064 16.3333 3.94064Z" fill="black"/>
                               <path d="M16.3333 8.48892C16.0303 8.48892 15.7514 8.34876 15.5858 8.11558C14.4045 6.44855 12.9955 4.21965 12.9955 3.07358C12.9942 1.37926 14.492 0 16.3333 0C18.1746 0 19.6725 1.37926 19.6725 3.07358C19.6725 4.21965 18.2635 6.44731 17.0822 8.11558C16.9152 8.35 16.6364 8.48892 16.3333 8.48892ZM16.3333 0.744207C14.9379 0.744207 13.8037 1.78982 13.8037 3.07358C13.8037 3.8029 14.7224 5.53566 16.2619 7.70875C16.2835 7.73851 16.3118 7.74471 16.3333 7.74471C16.3549 7.74471 16.3832 7.73851 16.4047 7.70875C17.9443 5.53566 18.8629 3.8029 18.8629 3.07358C18.8629 1.78982 17.7288 0.744207 16.3333 0.744207Z" fill="black"/>
                               <path d="M9.81263 22.0001H8.39292C8.16932 22.0001 7.98883 21.8339 7.98883 21.628C7.98883 21.4221 8.16932 21.2559 8.39292 21.2559H9.81263C10.0362 21.2559 10.2167 21.4221 10.2167 21.628C10.2167 21.8339 10.0349 22.0001 9.81263 22.0001Z" fill="black"/>
                               <path d="M5.81756 22.0001H4.39921C4.17561 22.0001 3.99512 21.8339 3.99512 21.628C3.99512 21.4221 4.17561 21.2559 4.39921 21.2559H5.81891C6.04251 21.2559 6.223 21.4221 6.223 21.628C6.223 21.8339 6.04116 22.0001 5.81756 22.0001Z" fill="black"/>
                               <path d="M1.82379 22.0001H0.40409C0.180493 22.0001 0 21.8339 0 21.628C0 21.4221 0.180493 21.2559 0.40409 21.2559H1.82379C2.04739 21.2559 2.22788 21.4221 2.22788 21.628C2.22788 21.8339 2.04604 22.0001 1.82379 22.0001Z" fill="black"/>
                           </svg>
                           <span>{data.routesById.distance ? data.routesById.distance +" km" :"-"}</span>
                       </div>
                       <div className={"card-info-item"}>
                           <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <g clipPath="url(#clip0)">
                                   <path d="M12 22C6.18221 22 0 20.8319 0 18.6678C0 16.9782 3.58681 16.1457 5.72505 15.8001C5.93796 15.7654 6.14042 15.9017 6.17699 16.1036C6.21356 16.3055 6.06988 16.4975 5.85697 16.5322C2.35245 17.0996 0.783716 18.0138 0.783716 18.6678C0.783716 19.7492 5.05105 21.2568 12 21.2568C18.9489 21.2568 23.2163 19.7492 23.2163 18.6678C23.2163 17.5889 19.0521 16.085 12.2717 16.085C12.0549 16.085 11.8798 15.919 11.8798 15.7134C11.8798 15.5078 12.0549 15.3418 12.2717 15.3418C17.9575 15.3418 24 16.5074 24 18.6678C24 20.8319 17.8178 22 12 22Z" fill="black"/>
                                   <path d="M10.2667 21.2345H9.48297V0.371626C9.48297 0.239081 9.55742 0.116446 9.6789 0.0495539C9.80038 -0.017338 9.95059 -0.0160993 10.0708 0.0507926L18.7987 4.86205C18.9189 4.92895 18.9934 5.05158 18.9934 5.18289C18.9934 5.31419 18.9189 5.43807 18.7987 5.50372L10.2667 10.2085V21.2345ZM10.2667 1.01701V9.34877L17.823 5.18289L10.2667 1.01701Z" fill="black"/>
                               </g>
                               <defs>
                                   <clipPath id="clip0">
                                       <rect width="24" height="22" fill="white"/>
                                   </clipPath>
                               </defs>
                           </svg>
                           <span>{data.routesById.route_markers.length + " " + translation.home.station}</span>
                       </div>
                       <div className={"card-info-item"}>
                           <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M13.1205 27.0008C12.316 27.0008 11.6627 26.3416 11.6627 25.53V16.3045H10.3373V25.53C10.3373 26.3416 9.68398 27.0008 8.87952 27.0008C8.07506 27.0008 7.42169 26.3416 7.42169 25.53V9.88678H1.45783C0.653373 9.88678 0 9.22762 0 8.41605C0 7.60447 0.653373 6.94531 1.45783 6.94531H20.5422C21.3466 6.94531 22 7.60447 22 8.41605C22 9.22762 21.3466 9.88678 20.5422 9.88678H14.5783V25.53C14.5783 26.3416 13.9249 27.0008 13.1205 27.0008ZM9.54217 15.5023H12.4578V25.53C12.4578 25.8991 12.7547 26.1986 13.1205 26.1986C13.4863 26.1986 13.7831 25.8991 13.7831 25.53V9.08456H20.5422C20.908 9.08456 21.2048 8.78507 21.2048 8.41605C21.2048 8.04703 20.908 7.74753 20.5422 7.74753H1.45783C1.09205 7.74753 0.795181 8.04703 0.795181 8.41605C0.795181 8.78507 1.09205 9.08456 1.45783 9.08456H8.21687V25.53C8.21687 25.8991 8.51373 26.1986 8.87952 26.1986C9.2453 26.1986 9.54217 25.8991 9.54217 25.53V15.5023Z" fill="black"/>
                               <path d="M11 5.61553C9.46529 5.61553 8.21686 4.35605 8.21686 2.80776C8.21686 1.25948 9.46529 0 11 0C12.5347 0 13.7831 1.25948 13.7831 2.80776C13.7831 4.35605 12.5347 5.61553 11 5.61553ZM11 0.802218C9.90397 0.802218 9.01204 1.70204 9.01204 2.80776C9.01204 3.91349 9.90397 4.81331 11 4.81331C12.096 4.81331 12.9879 3.91349 12.9879 2.80776C12.9879 1.70204 12.096 0.802218 11 0.802218Z" fill="black"/>
                           </svg>
                           <span>{data.routesById.difficulty ? difficulty[data.routesById.difficulty*1-1][lang] : "-"}</span>
                       </div>
                   </div>
               </div>
                <Typography variant="h2" component="h2" className={"header-2"} style={{textAlign:"center", marginTop: "90px"}}>
                    {translation.common.tours}
                </Typography>
                <div className={"marker-list"}>
                    <div style={{margin: "20px 0 20px 0"}}>
                        {
                            data.routesById.route_markers.map( (ea, i) => {
                                return <div key={i+"-marker"} className={"marker-list-item"}>
                                    <img style={{cursor: "pointer"}} src={ea.marker.markerImage?.path} onClick={()=>{
                                        history.push("/allomas/"+data.routesById.id+"/"+ea.id)
                                    }}/>
                                    <div>
                                        <Typography variant="h6" component="h6">
                                            {i+1+". " +ea.marker.title[lang]}
                                        </Typography>
                                    </div>
                                    <Button variant="outlined" onClick={()=>{
                                        history.push("/allomas/"+data.routesById.id+"/"+ea.id)
                                    }}>
                                        {translation.common.details}
                                    </Button>
                                </div>
                            })
                        }
                    </div>
                    {
                        mobile ? null : <div style={{margin: "90px auto 90px", position:"relative"/*maxWidth: "1140px"*/, height: "400px"}}><GoogleMap height={"400px"} route={data} /></div>
                    }
                </div>
            </Container>,

            mobile ?
                <AppBar position="static" style={{background:"#fff"}} className={'stickToBottom submenu'} key={"3"}>
                    <Tabs
                        value={1}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab component="a" icon={<HomeIcon />}/>
                        <Tab component="a" icon={<DirectionsWalkIcon />}/>
                        <Tab component="a" icon={<RoomIcon />} disabled={!selectedMarker}/>
                        <Tab component="a" icon={<MapIcon />}/>
                    </Tabs>
                </AppBar>
                : null
        ]
    );
}

export default Utvonal;
