import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ReactGA from 'react-ga';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';


export const initGA = () => {
    ReactGA.initialize('UA-88672426-3'); // put your tracking id here
}

export const GApageView = (page) => {
    ReactGA.pageview(page);
}

const client = new ApolloClient({
    uri: '/api/graphql', // '/api/graphql',//'http://localhost:5000/graphql',// 'http://bva.combit.hu/api/graphql',
});
initGA();
ReactDOM.render(
    <ApolloProvider client={client}>
        <App/>
    </ApolloProvider>,
  document.getElementById('root')
);
